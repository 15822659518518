<!-- eslint-disable vue/no-v-html -->
<template>
  <fragment>
    <div
      v-if="!isLoading"
      class="d-none d-md-block mt-3 mb-1"
    >
      <v-row>
        <v-card
          class="my-2"
          width="100%"
        >
          <v-container
            grid-list-xs
            class="pa-6"
          >
            <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
              <v-col cols="8">
                <div class="d-block">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <p class="mb-3 d-flex justify-start">
                        <b v-if="cotizadorCarsResultSelect.similares.length === 0">
                          {{ cotizadorCarsResultSelect.car.name }}
                          <v-chip
                            class="ma-2"
                            color="primary"
                          >
                            <span v-if="cotizadorCarsResultSelect.onRequest">On Request</span>
                            <span v-else>Free Sale</span>
                          </v-chip>
                        </b>
                        <fragment v-else>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                style="cursor: pointer"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <b>
                                  {{ `${cotizadorCarsResultSelect.car.name} ${$t('lbl.orSimilar')}` }}
                                  <v-chip
                                    class="ma-2"
                                    color="primary"
                                  >
                                    <span v-if="cotizadorCarsResultSelect.onRequest">On Request</span>
                                    <span v-else>Free Sale</span>
                                  </v-chip>
                                </b>
                              </span>
                            </template>
                            <span>
                              <span
                                v-for="(simi, indSimi) in cotizadorCarsResultSelect.similares"
                                :key="indSimi"
                              >
                                {{ simi.name }}<br />
                              </span>
                            </span>
                          </v-tooltip>
                        </fragment>
                      </p>
                    </v-col>
                    <!--ICONS PUERTAS, MALESTAS, PAXS-->
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <fragment>
                        <v-tooltip
                          top
                          color="primary"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ icons.mdiCarDoor }}
                            </v-icon>
                          </template>
                          <span>
                            <fragment>
                              {{ $t('lbl.typeCar') }}
                              {{
                                cotizadorCarsResultSelect.car.tipo_auto_id
                                  ? cotizadorCarsResultSelect.car.tipo_auto.name
                                  : ''
                              }}
                              {{ cotizadorCarsResultSelect.car.cant_puertas }} {{ $t('lbl.doors').toLowerCase() }}<br />
                              {{ cotizadorCarsResultSelect.car.cant_paxs }} {{ $t('lbl.travels').toLowerCase() }} <br />

                              {{
                                cotizadorCarsResultSelect.car.cant_maletas_small
                                  ? `${cotizadorCarsResultSelect.car.cant_maletas_small} ${$t(
                                    'lbl.cantMaletas',
                                  ).toLowerCase()} ${$t('lbl.small').toLowerCase()}`
                                  : ''
                              }}
                              <br />
                              {{
                                cotizadorCarsResultSelect.car.cant_maletas_big
                                  ? `${cotizadorCarsResultSelect.car.cant_maletas_big} ${$t(
                                    'lbl.cantMaletas',
                                  ).toLowerCase()} ${$t('lbl.big').toLowerCase()}`
                                  : ''
                              }}
                              <br />
                            </fragment>
                          </span>
                        </v-tooltip>
                        <b class="ml-2">
                          {{ cotizadorCarsResultSelect.car.cant_puertas }}
                        </b>
                      </fragment><br />
                      <fragment>
                        <v-tooltip
                          top
                          color="primary"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ icons.mdiAccountOutline }}
                            </v-icon>
                          </template>
                          <span>
                            <fragment>
                              {{ $t('lbl.typeCar') }}
                              {{
                                cotizadorCarsResultSelect.car.tipo_auto_id
                                  ? cotizadorCarsResultSelect.car.tipo_auto.name
                                  : ''
                              }}
                              {{ cotizadorCarsResultSelect.car.cant_puertas }} {{ $t('lbl.doors').toLowerCase() }}<br />
                              {{ cotizadorCarsResultSelect.car.cant_paxs }} {{ $t('lbl.travels').toLowerCase() }} <br />

                              {{
                                cotizadorCarsResultSelect.car.cant_maletas_small
                                  ? `${cotizadorCarsResultSelect.car.cant_maletas_small} ${$t(
                                    'lbl.cantMaletas',
                                  ).toLowerCase()} ${$t('lbl.small').toLowerCase()}`
                                  : ''
                              }}
                              <br />
                              {{
                                cotizadorCarsResultSelect.car.cant_maletas_big
                                  ? `${cotizadorCarsResultSelect.car.cant_maletas_big} ${$t(
                                    'lbl.cantMaletas',
                                  ).toLowerCase()} ${$t('lbl.big').toLowerCase()}`
                                  : ''
                              }}
                              <br />
                            </fragment>
                          </span>
                        </v-tooltip>
                        <b class="ml-3">{{ cotizadorCarsResultSelect.car.cant_paxs }}</b>
                      </fragment><br />
                      <fragment>
                        <v-tooltip
                          top
                          color="primary"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ icons.mdiBagSuitcaseOutline }}
                            </v-icon>
                          </template>
                          <span>
                            <fragment>
                              {{ $t('lbl.typeCar') }}
                              {{
                                cotizadorCarsResultSelect.car.tipo_auto_id
                                  ? cotizadorCarsResultSelect.car.tipo_auto.name
                                  : ''
                              }}
                              {{ cotizadorCarsResultSelect.car.cant_puertas }} {{ $t('lbl.doors').toLowerCase() }}<br />
                              {{ cotizadorCarsResultSelect.car.cant_paxs }} {{ $t('lbl.travels').toLowerCase() }} <br />

                              {{
                                cotizadorCarsResultSelect.car.cant_maletas_small
                                  ? `${cotizadorCarsResultSelect.car.cant_maletas_small} ${$t(
                                    'lbl.cantMaletas',
                                  ).toLowerCase()} ${$t('lbl.small').toLowerCase()}`
                                  : ''
                              }}
                              <br />
                              {{
                                cotizadorCarsResultSelect.car.cant_maletas_big
                                  ? `${cotizadorCarsResultSelect.car.cant_maletas_big} ${$t(
                                    'lbl.cantMaletas',
                                  ).toLowerCase()} ${$t('lbl.big').toLowerCase()}`
                                  : ''
                              }}
                              <br />
                            </fragment>
                          </span>
                        </v-tooltip>
                        <b class="ml-2">
                          {{
                            `${parseInt(cotizadorCarsResultSelect.car.cant_maletas_big) +
                              parseInt(cotizadorCarsResultSelect.car.cant_maletas_small)}`
                          }}
                        </b>
                      </fragment>
                    </v-col>
                    <!--CAPACIDADES-->
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <span class="caption">
                        {{ $t('lbl.capacityComb') }}: {{ cotizadorCarsResultSelect.car.capacidad_combustible }}
                        {{ cotizadorCarsResultSelect.car.um_combustible }}
                      </span>
                      <br />
                      <span class="caption mt-0">
                        {{ $t('lbl.consumoComb') }}: {{ cotizadorCarsResultSelect.car.consumo_combustible }}
                        {{ `km/${cotizadorCarsResultSelect.car.um_combustible}` }}
                      </span>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-img
                        v-if="cotizadorCarsResultSelect.rentadora.icon !== null"
                        :src="rutaPublic + cotizadorCarsResultSelect.rentadora.icon"
                        size="20"
                        height="30"
                        contain
                        aspect-ratio="1"
                      ></v-img>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="4">
                <v-img
                  :src="
                    nameB2B === 'ttttt'
                      ? rutaS3 + remplaceUrl(image)
                      : nameB2B === 'wwwww'
                        ? rutaS3 + image
                        : rutaPublic + image
                  "
                  size="100"
                  height="105"
                ></v-img>
              </v-col>
              <v-col
                cols="12"
                offset-md="2"
                md="10"
                class="py-0 mb-5"
              >
                <fragment
                  v-for="(cara, indCara) in cotizadorCarsResultSelect.car.caracteristicas"
                  :key="indCara"
                  top
                >
                  <v-tooltip
                    v-if="cara"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ cara.icon }}
                      </v-icon>
                    </template>
                    <span>{{ cara.name }}</span>
                  </v-tooltip>
                </fragment>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card
          class="my-2"
          width="100%"
        >
          <v-container
            grid-list-xs
            class="pa-6"
          >
            <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
              <v-col cols="12">
                <div class="d-block">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <p class="mb-0 d-flex justify-start">
                        <b>{{ $t('lbl.detallesReserva') }}</b>
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <b>{{ $t('lbl.recogida') }}</b>
                        </v-col>
                        <v-col
                          cols="12"
                          md="1"
                        >
                          <v-icon
                            v-if="recogida.pto_recogida"
                            color="primary"
                            class="mr-2"
                            small
                          >
                            {{ recogida.punto_recogida.icon }}
                          </v-icon>
                        </v-col>
                        <v-col
                          cols="12"
                          md="11"
                        >
                          <span class="caption">
                            {{ recogida.name }}<br />
                            {{ recogida.direccion }}<br />
                            {{ `${recogida.city}, ${recogida.state}, ${recogida.country}` }}<br />
                          </span>
                        </v-col>

                        <v-col
                          cols="12"
                          md="1"
                        >
                          <v-icon
                            color="primary"
                            class="mr-2"
                            small
                          >
                            mdi-calendar-range
                          </v-icon>
                        </v-col>
                        <v-col
                          cols="12"
                          md="11"
                        >
                          <span class="caption">
                            {{ $moment(cotizadorCars.dateRecogida).format('lll') }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <b>{{ $t('lbl.entrega') }}</b>
                        </v-col>
                        <v-col
                          cols="12"
                          md="1"
                        >
                          <v-icon
                            v-if="entrega.pto_recogida"
                            color="primary"
                            class="mr-2"
                            small
                          >
                            {{ entrega.punto_recogida.icon }}
                          </v-icon>
                        </v-col>
                        <v-col
                          cols="12"
                          md="11"
                        >
                          <span class="caption">
                            {{ entrega.name }}<br />
                            {{ entrega.direccion }}<br />
                            {{ `${entrega.city}, ${entrega.state}, ${entrega.country}` }}<br />
                          </span>
                        </v-col>

                        <v-col
                          cols="12"
                          md="1"
                        >
                          <v-icon
                            color="primary"
                            class="mr-2"
                            small
                          >
                            mdi-calendar-range
                          </v-icon>
                        </v-col>
                        <v-col
                          cols="12"
                          md="11"
                        >
                          <span class="caption">
                            {{ $moment(cotizadorCars.dateEntrega).format('lll') }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <b>{{ $t('lbl.daysRent') }}</b>: {{ cotizadorCarsResultSelect.diasReservar }}
                          <fragment v-if="cotizadorCarsResultSelect.dayGrace > 0">
                            <span class="caption">({{ $t('lbl.incluyeDayGrace') }})</span>
                            <v-tooltip
                              content-class="custom-tooltip"
                              top
                              max-width="250px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="primary">
                                    mdi-information-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span class="caption">
                                <v-row no-gutters>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <div class="d-block">
                                      <div class="d-flex justify-start align-center">
                                        <p class="mb-1 mt-2">
                                          <b>{{ $t('lbl.incluyeDayGraceMsg') }}</b>
                                        </p>
                                      </div>
                                    </div>
                                  </v-col>
                                </v-row>
                              </span>
                            </v-tooltip>
                          </fragment>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!--SUPLEMENTOS-->
                    <v-col
                      v-if="cotizadorCarsResultSelect.suplementos.length > 0"
                      cols="12"
                      md="12"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <b>{{ $t('menu.suplementos') }}</b>
                        </v-col>
                        <v-col
                          v-for="(suplemento, indSuple) in cotizadorCarsResultSelect.suplementos"
                          :key="indSuple"
                          cols="12"
                          md="6"
                        >
                          <v-checkbox
                            v-model="suplemento.check"
                            :label="suplemento.suplemento.name"
                            :disabled="suplemento.obligatorio"
                            hide-details
                            class="my-0"
                            @change="verifySuplement(suplemento)"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <!--FORM FINALIZAR-->
        <FormFinalizarCar />

        <v-card width="100%">
          <v-container grid-list-xs>
            <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
              <div class="d-block">
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-5 pb-0"
                  >
                    <b class="ml-5">
                      {{ $t('lbl.infoImportant') }}
                    </b>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-0 pb-0 mt-0 mb-0"
                  >
                    <span class="ml-5">
                      <v-checkbox
                        v-model="infoImportant"
                        :label="$t('lbl.readTermsConditions')"
                        hide-details
                        class="my-0 ml-3 pt-0 pb-0"
                        @click="changeInfoImportant"
                      ></v-checkbox>
                    </span>
                  </v-col>
                </v-row>

                <!--CONDITIONES GENERALES-->
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-expansion-panels>
                      <v-expansion-panel
                        v-for="(cate, ind) in categCondicionesGenerales"
                        :key="ind"
                      >
                        <v-expansion-panel-header v-if="cate.condicionesGenerales.length > 0">
                          {{ cate.name }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <fragment
                            v-for="(cond, indCond) in condicionesGenerales"
                            :key="indCond"
                          >
                            <fragment v-if="cate.id === cond.category_id">
                              <v-row
                                v-if="condicionesGenerales_id.includes(cond.id)"
                                no-gutters
                              >
                                <v-col
                                  cols="12"
                                  md="12"
                                >
                                  <v-checkbox
                                    v-model="condicionesGenerales_id"
                                    disabled
                                    :value="cond.id"
                                    hide-details
                                    class="pt-0"
                                  >
                                    <template v-slot:label>
                                      <p v-html="cond.description"></p>
                                    </template>
                                  </v-checkbox>
                                </v-col>
                              </v-row>
                              <!--<h5>
                                <span v-if="condicionesGenerales_id.includes(cond.id)">
                                  <v-checkbox
                                    v-model="condicionesGenerales_id"
                                    disabled
                                    :label="cond.description"
                                    :value="cond.id"
                                    hide-details
                                    class="pt-0"
                                  ></v-checkbox>
                                </span>
                              </h5>-->
                              <!--<v-checkbox
                                v-model="condicionesGenerales_id"
                                :label="cond.description"
                                :value="cond.id"
                                hide-details
                                class="pt-0"
                              ></v-checkbox>-->
                            </fragment>
                          </fragment>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <!--<v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ $t('lbl.termsConditions') }}
                          <template v-slot:actions>
                            <v-icon color="primary">
                              $expand
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ $t('lbl.policesCancele') }}
                          <template v-slot:actions>
                            <v-icon color="primary">
                              $expand
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ $t('lbl.docConductor') }}
                          <template v-slot:actions>
                            <v-icon color="primary">
                              $expand
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          {{ $t('lbl.policesComb') }}
                          <template v-slot:actions>
                            <v-icon color="primary">
                              $expand
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>-->
                  </v-col>
                </v-row>
              </div>
            </v-row>
          </v-container>
        </v-card>

        <!--<v-card
          class="my-2"
          width="100%"
        >
          <v-container grid-list-xs>
            <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
              <v-col
                cols="12"
                md="4"
              >
                <v-btn
                  color="primary"
                  width="100%"
                  :disabled="declineTerms || !driverAgeCumple"
                  :loading="loading"
                  @click="reserveNow"
                >
                  {{ $t('btn.reserveNow') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>-->
      </v-row>
    </div>
    <v-row
      v-if="isLoading"
      class="my-2"
    >
      <v-card
        width="100%"
        class="mx-2 pa-4 pt-0"
      >
        <div class="align-center">
          <v-skeleton-loader
            v-for="(i, ind) in 4"
            :key="ind"
            class="mx-auto"
            type="table-tfoot"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto"
            type="actions"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto"
            type="actions"
          ></v-skeleton-loader>
        </div>
      </v-card>
    </v-row>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar, mdiBed, mdiCarDoor, mdiAccountOutline, mdiBagSuitcaseOutline,
} from '@mdi/js'

import FormFinalizarCar from './FormFinalizarCar.vue'

// import Ida from './Ida.vue'
// import IdaBack from './IdaBack.vue'
// import IdaDestinos from './IdaDestinos.vue'

export default {
  components: {
    FormFinalizarCar,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      itemsOrigen: [],
      isLoading: true,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      image: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      recogida: null,
      entrega: null,
      vrad: 0,
      value: null,
      value1: null,
      icons: {
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
      },
      infoImportant: false,
      is_nivel_propietario: false,
      categCondicionesGenerales: [],
      condicionesGenerales: [],
      condicionesGenerales_id: [],
      checkbox1: true,
      user: {},
      age: null,
      ageAditional: null,
    }
  },
  computed: {
    ...mapState({
      driverAgeCumple: state => state.app.driverAgeCumple,
      declineTerms: state => state.app.declineTerms,
      cotizadorCars: state => state.app.cotizadorCars,
      cotizadorCarsResult: state => state.app.cotizadorCarsResult,
      cotizadorCarsResultSelect: state => state.app.cotizadorCarsResultSelect,
      cotizadorPaxCarSelect: state => state.app.cotizadorPaxCarSelect,
      noDocumentConductorPrincipalScanner: state => state.app.noDocumentConductorPrincipalScanner,
      noDocumentConductorAditionalScanner: state => state.app.noDocumentConductorAditionalScanner,
    }),
  },
  created() {
    this.profile()
    this.getOficinas()
    this.getCar()

    // this.getCategCondiciones()
    this.getCondiciones()

    this.condicionesGenerales_id = this.cotizadorCarsResultSelect.conditions
  },
  methods: {
    ...mapMutations([
      'deleteDestinoCotizadorFligth',
      'setSearchingFlightCotizador',
      'setDeclineTerms',
      'updateDriverAgeCumple',
    ]),
    remplaceUrl(url) {
      let img = url.replace('uploads/car/', '')
      img = img.replace(' ', '%20')

      return img
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1 || response.data.data.nivel === 0) {
            this.is_nivel_propietario = true
            this.infoImportant = true
            this.setDeclineTerms(false)
          }

          this.user = response.data.data
        })
    },
    getOficinas() {
      if (this.cotizadorCars.recogida) {
        if (this.cotizadorCars.recogida.type === 'oficina') {
          this.axios
            .get(`nom_oficina_renta_cars/${this.cotizadorCars.recogida.id}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              this.recogida = res.data.data.data
              if (!this.cotizadorCars.entrega) {
                this.entrega = this.recogida
              }
            })
        } else if (this.cotizadorCars.recogida.type === 'destino') {
          this.axios
            .get(`nom_oficina_renta_cars/${this.cotizadorCarsResultSelect.recogida.id}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              this.recogida = res.data.data.data
              if (!this.cotizadorCars.entrega) {
                this.entrega = this.recogida
              }
            })
        }
      }
      if (this.cotizadorCars.entrega) {
        if (this.cotizadorCars.entrega.type === 'oficina') {
          this.axios
            .get(`nom_oficina_renta_cars/${this.cotizadorCars.entrega.id}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              this.entrega = res.data.data.data
            })
        } else if (this.cotizadorCars.entrega.type === 'destino') {
          this.axios
            .get(`nom_oficina_renta_cars/${this.cotizadorCarsResultSelect.entrega.id}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              this.entrega = res.data.data.data
            })
        }
      }
    },
    getCar() {
      if (this.cotizadorCarsResultSelect.car.galery === null) {
        this.image = 'config/car_placeholder.png'
      } else {
        this.image = this.cotizadorCarsResultSelect.car.galery_random
      }

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    getCategCondiciones() {
      this.axios
        .get('nom_categories_conditions?for_car=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categCondicionesGenerales = []

            // this.allCategCondicionesGenerales = res.data.data
            res.data.data.forEach(element => {
              const conditions = []
              this.condicionesGenerales.forEach(cond => {
                if (
                  cond.category_id === element.id
                  && this.cotizadorCarsResultSelect.proveedor_id === cond.proveedor_id
                ) {
                  conditions.push({
                    ...cond,
                    show: true,
                  })
                }
              })
              this.categCondicionesGenerales.push({
                ...element,
                filtro: null,
                condicionesGenerales: conditions,
              })
            })
          }
        })
    },
    getCondiciones() {
      this.axios
        .get('nom_conditions_generals?for_car=1&per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.condicionesGenerales = res.data.data
          }
        })
        .finally(() => this.getCategCondiciones())
    },
    verifySuplement(suplemento) {
      const slugSup = suplemento.suplemento.slug

      if (suplemento.check) {
        // this.cotizadorCarsResultSelect.tarifaAllDay += suplemento.priceAll
        // this.cotizadorCarsResultSelect.priceTotal += suplemento.priceAll
        // this.cotizadorCarsResultSelect.priceWithMarkup += suplemento.priceAll
        // this.cotizadorCarsResultSelect.priceWithDescuento += suplemento.priceAll

        if (slugSup === 'conductor-extra') {
          this.cotizadorPaxCarSelect.conductorAditional = true
        } else if (slugSup === 'tanque-de-combustible-lleno') {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.cotizadorCarsResultSelect.suplementos.length; i++) {
            if (this.cotizadorCarsResultSelect.suplementos[i].suplemento) {
              if (
                this.cotizadorCarsResultSelect.suplementos[i].suplemento.slug === 'tanque-de-combustible-lleno'
                && this.cotizadorCarsResultSelect.suplementos[i].suplemento.id === suplemento.suplemento_id
              ) {
                suplemento.tarifas_combustible.forEach(combo => {
                  if (this.cotizadorCarsResultSelect.category_id) {
                    if (combo.category_id) {
                      if (combo.category_id === this.cotizadorCarsResultSelect.category_id.id) {
                        this.cotizadorCarsResultSelect.suplementos[i].price = combo.price
                        this.cotizadorCarsResultSelect.suplementos[i].priceAll = combo.price
                      }
                    }
                  } else if (this.cotizadorCarsResultSelect.marca_id) {
                    if (combo.marca_id) {
                      if (combo.marca_id === this.cotizadorCarsResultSelect.marca_id) {
                        this.cotizadorCarsResultSelect.suplementos[i].price = combo.price
                        this.cotizadorCarsResultSelect.suplementos[i].priceAll = combo.price
                      }
                    }
                  }
                })
              }
            }
          }
        }
      } else {
        // this.cotizadorCarsResultSelect.tarifaAllDay -= suplemento.priceAll
        // this.cotizadorCarsResultSelect.priceTotal -= suplemento.priceAll
        // this.cotizadorCarsResultSelect.priceWithMarkup -= suplemento.priceAll
        // this.cotizadorCarsResultSelect.priceWithDescuento -= suplemento.priceAll

        // eslint-disable-next-line no-lonely-if
        if (slugSup === 'conductor-extra') {
          this.cotizadorPaxCarSelect.conductorAditional = false

          if (!this.cotizadorPaxCarSelect.conductorAditional) {
            this.cotizadorPaxCarSelect.nameConductorAditional = null
            this.cotizadorPaxCarSelect.apellidosConductorAditional = null
            this.cotizadorPaxCarSelect.nameSecoundConductorAditional = null
            this.cotizadorPaxCarSelect.birthdayConductorAditional = null
            this.cotizadorPaxCarSelect.nationalityConductorAditional = null
            this.cotizadorPaxCarSelect.sexConductorAditional = null
            this.cotizadorPaxCarSelect.noDocumentConductorAditional = null
            this.ageAditional = null
          }

          this.changeBithdayMain()
        }
      }
    },
    changeBithdayMain() {
      if (this.cotizadorPaxCarSelect.birthdayConductorPrincipal) {
        /* const dob = new Date(this.cotizadorPaxCarSelect.birthdayConductorPrincipal)
        const diff_ms = new Date(this.cotizadorCars.dateRecogida) - dob.getTime()
        const age_dt = new Date(diff_ms)
        this.age = Math.abs(age_dt.getUTCFullYear() - 1970) */

        this.age = Math.abs(
          this.$moment(this.$moment(this.cotizadorCars.dateRecogida, 'YYYY-MM-DD')).diff(
            this.$moment(this.cotizadorPaxCarSelect.birthdayConductorPrincipal, 'YYYY-MM-DD'),
            'years',
            true,
          ),
        )

        if (this.cotizadorCarsResultSelect.edad) {
          if (!this.cotizadorCarsResultSelect.edad_max) {
            if (this.cotizadorCarsResultSelect.edad > this.age) {
              this.$toast.error(this.$t('msg.edadDriver1', { edad: this.cotizadorCarsResultSelect.edad }))
              this.updateDriverAgeCumple(false)
            } else {
              this.updateDriverAgeCumple(true)
            }
          } else if (
            this.cotizadorCarsResultSelect.edad > this.age
            || this.cotizadorCarsResultSelect.edad_max <= this.age
          ) {
            this.updateDriverAgeCumple(false)
          } else {
            this.updateDriverAgeCumple(true)
          }
        } else {
          this.updateDriverAgeCumple(true)
        }
      }
      if (this.cotizadorPaxCarSelect.birthdayConductorAditional) {
        /* const dob = new Date(this.cotizadorPaxCarSelect.birthdayConductorAditional)
        const diff_ms = new Date(this.cotizadorCars.dateRecogida) - dob.getTime()
        const age_dt = new Date(diff_ms)

        this.ageAditional = Math.abs(age_dt.getUTCFullYear() - 1970) */

        this.ageAditional = Math.abs(
          this.$moment(this.$moment(this.cotizadorCars.dateRecogida, 'YYYY-MM-DD')).diff(
            this.$moment(this.cotizadorPaxCarSelect.birthdayConductorAditional, 'YYYY-MM-DD'),
            'years',
            true,
          ),
        )

        if (this.cotizadorCarsResultSelect.edad) {
          if (!this.cotizadorCarsResultSelect.edad_max) {
            if (this.cotizadorCarsResultSelect.edad > this.ageAditional) {
              this.updateDriverAgeCumple(false)
            } else {
              this.updateDriverAgeCumple(true)
            }
          } else if (
            this.cotizadorCarsResultSelect.edad > this.ageAditional
            || this.cotizadorCarsResultSelect.edad_max <= this.ageAditional
          ) {
            this.updateDriverAgeCumple(false)
          } else {
            this.updateDriverAgeCumple(true)
          }
        } else {
          this.updateDriverAgeCumple(true)
        }
      }

      // PARA VALIDAR EL SEGURO DE EDAD DEL CONDUCTOR
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.cotizadorCarsResultSelect.suplementos.length; i++) {
        if (this.cotizadorCarsResultSelect.suplementos[i].suplemento) {
          if (this.cotizadorCarsResultSelect.suplementos[i].suplemento.slug === 'seguro-extra-por-edad-del-conductor') {
            if (this.cotizadorCarsResultSelect.suplementos[i].seguros_edad_conductor) {
              this.cotizadorCarsResultSelect.suplementos[i].seguros_edad_conductor.forEach(seguro => {
                let arrAge = -1
                if (this.cotizadorCarsResultSelect.category_id) {
                  arrAge = this.cotizadorCarsResultSelect.category_id.id
                }
                if (seguro.category_id.includes(-1) || seguro.category_id.includes(arrAge)) {
                  // eslint-disable-next-line camelcase
                  const { edad_min } = seguro
                  // eslint-disable-next-line camelcase
                  const { edad_max } = seguro

                  if (this.age) {
                    // eslint-disable-next-line camelcase
                    if (this.age < edad_min || this.age > edad_max) {
                      this.cotizadorCarsResultSelect.suplementos[
                        i
                      ].priceAll = this.cotizadorCarsResultSelect.suplementos[i].priceAllOrigin
                    } else {
                      this.cotizadorCarsResultSelect.suplementos[i].priceAll = 0
                    }
                  }
                  if (this.ageAditional) {
                    // eslint-disable-next-line camelcase
                    if (this.ageAditional < edad_min || this.ageAditional > edad_max) {
                      let number = 1
                      if (this.age) {
                        // eslint-disable-next-line camelcase
                        if (this.age < edad_min || this.age > edad_max) {
                          number = 2
                        }
                      }
                      this.cotizadorCarsResultSelect.suplementos[i].priceAll = this.cotizadorCarsResultSelect.suplementos[i].priceAllOrigin * number
                    } else if (this.age) {
                      // eslint-disable-next-line camelcase
                      if (this.age < edad_min || this.age > edad_max) {
                        this.cotizadorCarsResultSelect.suplementos[
                          i
                        ].priceAll = this.cotizadorCarsResultSelect.suplementos[i].priceAllOrigin
                      } else {
                        this.cotizadorCarsResultSelect.suplementos[i].priceAll = 0
                      }
                    } else {
                      this.cotizadorCarsResultSelect.suplementos[i].priceAll = 0
                    }
                  }
                }
              })
            }
          }
        }
      }
    },
    changeInfoImportant() {
      this.setDeclineTerms(!this.infoImportant)
    },

    /* reserveNow() {
      let procesar = true
      if (
        !this.cotizadorPaxCarSelect.nameConductorPrincipal
        || !this.cotizadorPaxCarSelect.apellidosConductorPrincipal
        || !this.cotizadorPaxCarSelect.birthdayConductorPrincipal
        || !this.cotizadorPaxCarSelect.nationalityConductorPrincipal
        || !this.cotizadorPaxCarSelect.noDocumentConductorPrincipal
      ) {
        procesar = false
      }

      if (this.cotizadorPaxCarSelect.conductorAditional) {
        if (
          !this.cotizadorPaxCarSelect.nameConductorAditional
          || !this.cotizadorPaxCarSelect.apellidosConductorAditional
          || !this.cotizadorPaxCarSelect.birthdayConductorAditional
          || !this.cotizadorPaxCarSelect.nationalityConductorAditional
          || !this.cotizadorPaxCarSelect.noDocumentConductorAditional
        ) {
          procesar = false
        }
      }

      if (procesar) {
        this.loading = true
        const suplementos = []
        this.cotizadorCarsResultSelect.suplementos.forEach(element => {
          if (element.check) {
            suplementos.push(element)
          }
        })

        // PAY TOTAL
        let priceTotal = this.cotizadorCarsResultSelect.priceTotal
          + parseFloat(this.cotizadorCarsResultSelect.tarifaSeguro)
          + parseFloat(this.cotizadorCarsResultSelect.tarifaGarantia)

        this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
          if (suplement.check) {
            priceTotal += suplement.priceAll
          }
        })
        const jsonItem = {
          car: this.cotizadorCarsResultSelect.car,
          category_id: this.cotizadorCarsResultSelect.category_id,
          marca_id: this.cotizadorCarsResultSelect.marca_id,
          contrate_id: this.cotizadorCarsResultSelect.contrate_id,
          cuposDisponibles: this.cotizadorCarsResultSelect.cuposDisponibles,
          diasReservar: this.cotizadorCarsResultSelect.diasReservar,
          priceTotal,

          // priceTotal: this.cotizadorCarsResultSelect.priceTotal,
          priceWithDescuento: this.cotizadorCarsResultSelect.priceWithDescuento,
          priceWithMarkup: this.cotizadorCarsResultSelect.priceWithMarkup,
          rentadora: this.cotizadorCarsResultSelect.rentadora,
          tarifaAllDay: this.cotizadorCarsResultSelect.tarifaAllDay,
          tarifaDay: this.cotizadorCarsResultSelect.tarifaDay,
          tarifaDayExtra: this.cotizadorCarsResultSelect.tarifaDayExtra,
          tarifaGarantia: this.cotizadorCarsResultSelect.tarifaGarantia,
          tarifaSeguro: this.cotizadorCarsResultSelect.tarifaSeguro,
          suplementos,
        }

        let recogida
        if (this.cotizadorCars.recogida.type === 'oficina') {
          recogida = this.cotizadorCars.recogida
        } else if (this.cotizadorCars.recogida.type === 'destino') {
          recogida = this.cotizadorCarsResultSelect.recogida
        }

        let entrega
        if (this.cotizadorCars.entrega) {
          if (this.cotizadorCars.entrega.type === 'oficina') {
            entrega = this.cotizadorCars.entrega
          } else if (this.cotizadorCars.entrega.type === 'destino') {
            entrega = this.cotizadorCarsResultSelect.entrega
          }
        } else {
          entrega = recogida
        }

        const jsonReserve = {
          entrega,
          dateEntrega: this.cotizadorCars.dateEntrega,
          recogida,
          dateRecogida: this.cotizadorCars.dateRecogida,
          entregaDiffRecogida: this.cotizadorCars.entregaDiffRecogida,
          items: jsonItem,
        }

        let payOnline = parseFloat(this.cotizadorCarsResultSelect.tarifaSeguro)
          + parseFloat(this.cotizadorCarsResultSelect.priceTotal)
        this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
          if (!!suplement.check && !!suplement.online) {
            payOnline += suplement.priceAll
          }
        })

        let payRentadora = parseFloat(this.cotizadorCarsResultSelect.tarifaGarantia)
        this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
          if (!!suplement.check && !suplement.online) {
            payRentadora += suplement.priceAll
          }
        })

        let payRetorno = 0
        this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
          if (suplement.suplemento.slug === 'tarifa-de-retorno' && suplement.check) {
            payRetorno = suplement.priceAll
          }
        })
        const jsonPrices = {
          priceTotal,
          priceTarifa: this.cotizadorCarsResultSelect.priceTotal,
          precioSuplementPayOnline: payOnline,
          precioSuplementPayRentator: payRentadora,
          precioTarifaRetorno: payRetorno,
          precioTarifaxKm: 0,
          diasReservar: this.cotizadorCarsResultSelect.diasReservar,
          edad: this.cotizadorCarsResultSelect.edad,
          edad_max: this.cotizadorCarsResultSelect.edad_max,
          conditions_id: this.cotizadorCarsResultSelect.conditions,
          contrate_id: this.cotizadorCarsResultSelect.contrate_id,
          proveedor_id: this.cotizadorCarsResultSelect.proveedor_id,
          markups: this.cotizadorCarsResultSelect.markups,
          descuento: this.cotizadorCarsResultSelect.priceWithDescuento,
          markup: this.cotizadorCarsResultSelect.priceWithMarkup,
          suplementos,
          tarifaAllNeto: this.cotizadorCarsResultSelect.tarifaAllDay,
          tarifaDayNeto: this.cotizadorCarsResultSelect.tarifaDay,
          tarifaDayExtra: this.cotizadorCarsResultSelect.tarifaDayExtra,
          tarifaGarantia: this.cotizadorCarsResultSelect.tarifaGarantia,
          tarifaSeguro: this.cotizadorCarsResultSelect.tarifaSeguro,
        }

        const json = {
          product: 'cars',
          data_client: this.cotizadorPaxCarSelect,
          data_markups: jsonPrices,

          // data_markups: this.cotizadorCarsResultSelect,
          data_service: jsonReserve,
          data_reserva: {},
          lang: this.$i18n.locale,
          user_id: this.user.id,
        }

        const formData = new FormData()
        if (this.noDocumentConductorPrincipalScanner) {
          formData.append('noDocumentConductorPrincipalScanner', this.noDocumentConductorPrincipalScanner)
        }
        if (this.noDocumentConductorAditionalScanner) {
          formData.append('noDocumentConductorAditionalScanner', this.noDocumentConductorAditionalScanner)
        }

        formData.append('data', JSON.stringify(json))

        this.axios
          .post(
            'reservations/generate',
            formData,
            { responseType: 'arraybuffer' },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            let filename = 'cotization'
            filename = `${this.cotizadorPaxCarSelect.nameConductorPrincipal} ${
              this.cotizadorPaxCarSelect.apellidosConductorPrincipal
            }_Autos_${this.$moment().format('M-D-Y')}`
            filename = filename.replace(/ /g, '-')

            this.downloadFile(res, filename)

            // PARA OBTENER EL ID DE LA ULTIMA RESERVA DEL GESTOR
            this.axios
              .get(`reservations/last-user/${this.user.id}`, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(reserv => {
                sessionStorage.setItem('reservations-id', reserv.data.data.data.id)
              })
              .finally(() => {
                this.$router.push({ name: 'success-cotitation' })
              })

            // this.$router.push({ name: 'success-cotitation' })
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
        this.loading = false
      }
    },
    downloadFile(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    }, */
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
