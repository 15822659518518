<template>
  <div
    class="d-none d-md-block"
  >
    <!-- ref="statiCard" style="position: fixed; z-index: 6; width: available"-->
    <v-col
      cols="12"
      md="12"
      class=""
    >
      <!--CALCULADORA-->
      <v-row
        v-if="showOnlyGestor"
        class="mb-3"
      >
        <v-card
          width="100%"
          class="mx-2 pa-4 pt-0"
        >
          <v-row>
            <v-col
              cols="4"
              class="pt-2"
            >
              <h1
                primary-title
                class="pa-0"
              >
                PVP
              </h1>
            </v-col>
            <v-col
              cols="8"
              class="pt-5 text-end"
            >
              <AppBarCurrencyPVP />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <v-row class="d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.precioNeto') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ precioNeto | currency(cotizadorCarsResultSelect.contrate_currency_symbol) }}
                        </span>
                      </template>
                      <span>
                        1 {{ cotizadorCarsResultSelect.currencyBaseCode }} = {{ tasaCambioContrate }} {{ cotizadorCarsResultSelect.contrate_currency_code }}
                      </span>
                    </v-tooltip>
                  </p>
                </div>
              </v-row>
            </v-col>
            <v-col
              cols="4"
            ></v-col>
            <v-col
              cols="4"
              class="text-end"
            >
              <v-row class="d-flex justify-end align-end mr-1">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-end align-end">
                    {{ $t('lbl.precioInitial') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-end align-end">
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ precioInitial | currency(currencySymbolFacturar) }}
                        </span>
                      </template>
                      <span>
                        1 {{ cotizadorCarsResultSelect.currencyBaseCode }} = {{ tasaCambioFacturar }} {{ currencyCodeFacturar }}
                      </span>
                    </v-tooltip>
                  </p>
                </div>
              </v-row>
            </v-col>
          </v-row>

          <!--MARKUPS-->
          <v-row class="pt-2">
            <v-col
              cols="4"
              class="pr-0"
            >
              <v-text-field
                v-model="markup"
                label="Mark-up"
                hide-details
                outlined
                width="auto"
                class="descuento"
                type="number"
                :disabled="isLoading"
                @change="validateMarkupsInput()"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="4"
              class="pr-0"
            >
              <v-radio-group
                v-model="valueMarkup"
                hide-details
                dense
                class="mt-0 pt-0 descuento"
                :disabled="isLoading"
                @change="validateMarkupsRadio()"
              >
                <v-radio
                  label="%"
                  class="descuento"
                  value="%"
                ></v-radio>
                <v-radio
                  :label="currencyCodeFacturar"
                  class="descuento"
                  :value="currencyCodeFacturar"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="4"
              class="text-end"
            >
              <h4 class="p12Mine">
                {{ $t('lbl.conMarkups') }}
              </h4>
              <v-divider></v-divider>
              <p class="p12Mine">
                {{ precioWithMarkup | currency(currencySymbolFacturar) }}
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-0 pt-0">
            <v-col
              cols="8"
              class="mt-0 pt-0"
            >
              <span>
                <span style="font-size: 10px">
                  {{ $t('lbl.incluyeMargenCom') }}
                </span>
                <fragment
                  v-if="markup !== 0 && valueMarkup !== null"
                >
                  <span style="font-size: 12px">
                    ({{ markup }}
                    <span v-if="valueMarkup === '%'">%</span>
                    <span v-else-if="valueMarkup === currencyCodeFacturar">{{ currencyCodeFacturar }}</span>)
                  </span>
                </fragment>
              </span>
            </v-col>
            <v-col
              cols="4"
              class="mt-0 pt-0 text-end"
              style="text-align: center;"
            >
              <span style="font-size: 12px">
                {{ valueMarkupCalculate | currency(currencySymbolFacturar) }}
              </span>
            </v-col>
          </v-row>

          <!--DESCUENTO-->
          <v-row
            v-if="agregarDescuento"
            class="pt-2"
          >
            <v-col
              cols="4"
              class="pr-0"
            >
              <v-text-field
                v-model="descuento"
                class="descuento"
                :label="$t('lbl.descuento')"
                hide-details
                outlined
                width="auto"
                type="number"
                @change="validateDescuentoInput"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="4"
              class="pr-0"
            >
              <v-radio-group
                v-model="valueDescuento"
                hide-details
                dense
                class="mt-0 pt-0"
              >
                <v-radio
                  label="%"
                  class="descuento"
                  value="%"
                ></v-radio>
                <v-radio
                  :label="currencyCodeFacturar"
                  class="descuento"
                  :value="currencyCodeFacturar"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="4"
              class="text-end"
            >
              <h4 class="p12Mine">
                {{ $t('lbl.conDescuento') }}
              </h4>
              <v-divider></v-divider>
              <p class="p12Mine mb-0">
                {{ precioWithDescuento | currency(currencySymbolFacturar) }}
              </p>
            </v-col>
          </v-row>
          <v-row
            v-if="agregarDescuento && valueDescuentoCalculate > 0"
            class="mt-0 pt-0"
          >
            <v-col
              cols="8"
              class="mt-0 pt-0"
            >
              <span>
                <span style="font-size: 10px">
                  {{ $t('lbl.incluyeDescuento') }}
                </span>
                <fragment
                  v-if="
                    descuento !== 0 && valueDescuento !== null
                  "
                >
                  <span style="font-size: 12px">
                    ({{ descuento }}
                    <span v-if="valueDescuento === '%'">%</span>
                    <span v-else-if="valueDescuento === currencyCodeFacturar">{{ currencyCodeFacturar }}</span>)
                  </span>
                </fragment>
              </span>
            </v-col>
            <v-col
              cols="4"
              class="mt-0 pt-0 text-end"
              style="text-align: center;"
            >
              <span style="font-size: 12px">
                {{ valueDescuentoCalculate | currency(currencySymbolFacturar) }}
              </span>
            </v-col>
          </v-row>

          <!--BTN-->
          <v-row>
            <v-col cols="7">
              <v-checkbox
                v-model="agregarDescuento"
                :label="$t('lbl.addDescuento')"
                dense
                hide-details
                outlined
                class="descuento my-0 py-0"
                value="1"
                :disabled="isLoading"
                @change="changeAgregarDescuento"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="4"
              class="align-self-center align-right"
            >
              <v-btn
                color="primary"
                :disabled="isLoading"
                @click="applyMarkups"
              >
                {{ $t('btn.apply') }}
              </v-btn>
            </v-col>
          </v-row>

          <!--
            OLD
            <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <v-col
                cols="12"
                class="py-0"
              >
                <h1
                  primary-title
                  class="pa-0"
                >
                  PVP
                </h1>
              </v-col>

              <v-row class="pt-2">
                <v-col
                  cols="7"
                  class="pr-0"
                >
                  <v-text-field
                    v-model="markup"
                    label="Markup"
                    hide-details
                    outlined
                    width="auto"
                    class="descuento"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="5"
                  class="pr-0"
                >
                  <v-radio-group
                    v-model="valueMarkup"
                    hide-details
                    dense
                    class="mt-0 pt-0 descuento"
                  >
                    <v-radio
                      label="%"
                      class="descuento"
                      value="%"
                    ></v-radio>
                    <v-radio
                      label="USD"
                      class="descuento"
                      value="$"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="agregarDescuento"
                  cols="7"
                  class="pr-0"
                >
                  <v-text-field
                    v-model="descuento"
                    class="descuento"
                    :label="$t('lbl.descuento')"
                    hide-details
                    outlined
                    width="auto"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="agregarDescuento"
                  cols="5"
                  class="pr-0"
                >
                  <v-radio-group
                    v-model="valueDescuento"
                    hide-details
                    dense
                    class="mt-0 pt-0"
                  >
                    <v-radio
                      label="%"
                      class="descuento"
                      value="%"
                    ></v-radio>
                    <v-radio
                      label="USD"
                      class="descuento"
                      value="$"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10">
                  <v-checkbox
                    v-model="agregarDescuento"
                    :label="$t('lbl.addDescuento')"
                    dense
                    hide-details
                    outlined
                    class="descuento my-0 py-0"
                    value="1"
                    @change="changeAgregarDescuento"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="2"
                  class="align-self-center align-right"
                >
                  <v-btn
                    color="primary"
                    @click="applyMarkups"
                  >
                    {{ $t('btn.apply') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="mt-12"
            >
              <v-row class="d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.precioInitial') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ cotizadorCarsResultSelect.tarifaAllDay | currency }}
                  </p>
                </div>
              </v-row>
              <v-row class="mt-6 d-flex justify-center align-center">
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.conMarkups') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ precioWithMarkup | currency }}
                  </p>
                </div>
              </v-row>
              <v-row
                v-if="agregarDescuento"
                class="mt-6 d-flex justify-center align-center"
              >
                <div class="d-block">
                  <h4 class="p12Mine d-flex justify-center align-center">
                    {{ $t('lbl.conDescuento') }}
                  </h4>
                  <v-divider></v-divider>
                  <p class="p12Mine mb-0 d-flex justify-center align-center">
                    {{ precioWithDescuento | currency }}
                  </p>
                </div>
              </v-row>
            </v-col>
          </v-row>-->
        </v-card>
      </v-row>

      <template v-if="!isLoading">
        <!--MOSTRAR PARA EL GESTOR-->
        <fragment v-if="showOnlyGestor">
          <!--<v-row>
            <v-card
              width="100%"
              class="mx-2 pa-4"
            >
              <div class="d-flex justify-space-between align-center">
                <h4 class="">
                  {{ $t('lbl.rentaCar') }}
                </h4>
                <h4>
                  <h4>
                    {{ cotizadorCarsResultSelect.tarifaAllDay | currency }}
                  </h4>
                </h4>
              </div>
              <div class="d-flex justify-space-between align-center mt-0">
                <span class="caption">
                  {{ $t('lbl.rentaCarByDay') }}
                </span>
                <span class="caption">
                  {{ cotizadorCarsResultSelect.tarifaDay | currency }}
                </span>
              </div>
              <div class="d-flex justify-space-between align-center mt-0">
                <h3>
                  {{ $t('lbl.total') }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiInformation }}
                      </v-icon>
                    </template>
                    <span>
                      <div class="d-block mb-2">
                        <div class="d-flex justify-start align-center">
                          <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                        </div>
                        <div class="d-flex justify-space-between align-center">
                          <p class="mb-0 pr-6">Tarifa</p>
                          <p class="mb-0">776,87 $</p>
                        </div>
                        <div class="d-flex justify-space-between align-center">
                          <p
                            class="mb-0 pr-6"
                            subtitle-2
                          >Impuestos y tasas</p>
                          <p
                            class="mb-0"
                            subtitle-2
                          >776,87 $</p>
                        </div>
                      </div>
                    </span>
                  </v-tooltip>
                </h3>

                <h3>
                  <h4>
                    {{ cotizadorCarsResultSelect.tarifaAllDay | currency }}
                  </h4>
                </h3>
              </div>
            </v-card>
          </v-row>-->
          <v-row class="my-2">
            <v-card
              width="100%"
              class="mx-2 pa-4 pt-0"
            >
              <!--<v-card-title
                primary-title
                class="px-0 py-2"
              >
                {{ $t('lbl.priceByCliente') }}
              </v-card-title>-->
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.tarif') }}
                </h4>
                <h4>
                  <h4>
                    {{ cotizadorCarsResultSelect.priceTotal | currency(currencySymbolFacturar) }}
                  </h4>
                </h4>
              </div>
              <!--<div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  <span>
                    {{ $t('lbl.incluyeMargenCom') }}
                  </span>
                  <fragment
                    v-if="cotizadorCarsSelectMarkups.markup !== 0 && cotizadorCarsSelectMarkups.valueMarkup !== null"
                  >
                    ({{ cotizadorCarsSelectMarkups.markup }}
                    <span v-if="cotizadorCarsSelectMarkups.valueMarkup === '%'">%</span>
                    <span v-else-if="cotizadorCarsSelectMarkups.valueMarkup === '$'">USD</span>)
                  </fragment>
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{
                    cotizadorCarsResultSelect.priceWithMarkup > 0
                      ? cotizadorCarsResultSelect.priceWithMarkup
                      : 0 | currency
                  }}
                </span>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  <span>
                    {{ $t('lbl.incluyeDescuento') }}
                  </span>
                  <fragment
                    v-if="
                      cotizadorCarsSelectMarkups.descuento !== 0 && cotizadorCarsSelectMarkups.valueDescuento !== null
                    "
                  >
                    ({{ cotizadorCarsSelectMarkups.descuento }}
                    <span v-if="cotizadorCarsSelectMarkups.valueDescuento === '%'">%</span>
                    <span v-else-if="cotizadorCarsSelectMarkups.valueDescuento === '$'">USD</span>)
                  </fragment>
                </span>
                <span style="font-size: 12px">
                  {{
                    cotizadorCarsResultSelect.priceWithDescuento > 0
                      ? cotizadorCarsResultSelect.priceWithDescuento
                      : 0 | currency
                  }}
                </span>
              </div>-->

              <!--SUPLEMENTOS-->
              <div class="d-flex justify-space-between align-center">
                <h4 class="my-1">
                  {{ $t('lbl.cargoSuplement') }}
                </h4>
                <h4>
                  <h4>
                    {{ (precioSuplementPayOnline + precioSuplementPayRentator) | currency(currencySymbolFacturar) }}
                  </h4>
                </h4>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ $t('lbl.seguro') }}
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ cotizadorCarsResultSelect.tarifaSeguro | currency(currencySymbolFacturar) }}
                </span>
              </div>
              <div
                v-for="(suplemento, indSupl) in cotizadorCarsResultSelect.suplementos"
                :key="Math.random() * (indSupl + 1)"
                class="d-flex justify-space-between align-center ml-2"
              >
                <fragment v-if="!!suplemento.online && !!suplemento.check">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ suplemento.suplemento.name }}
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ suplemento.priceAll | currency(currencySymbolFacturar) }}
                  </span>
                </fragment>
              </div>
              <!--PAY EN RENTADORA-->
              <div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ $t('lbl.depositoGarantia') }}
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ cotizadorCarsResultSelect.tarifaGarantia | currency(currencySymbolFacturar) }}
                </span>
              </div>
              <div
                v-for="(suplemento, indSupl) in cotizadorCarsResultSelect.suplementos"
                :key="Math.random() * (indSupl + 1)"
                class="d-flex justify-space-between align-center ml-2"
              >
                <fragment v-if="!suplemento.online && !!suplemento.check">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ suplemento.suplemento.name }}
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ suplemento.priceAll | currency(currencySymbolFacturar) }}
                  </span>
                </fragment>
              </div>
            </v-card>

            <!--<v-card
              v-if="precioSuplementPayOnline > 0 || precioSuplementPayRentator > 0"
              width="100%"
              class="mx-2 mt-3 pa-4 pt-0"
            >
              <v-card-title
                primary-title
                class="px-0 py-2"
              >
                {{ $t('lbl.cargoSuplement') }}
              </v-card-title>
              <fragment v-if="precioSuplementPayOnline > 0">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="my-1">
                    {{ $t('lbl.payOnline') }}
                  </h4>
                  <h4>
                    {{ precioSuplementPayOnline | currency }}
                  </h4>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ $t('lbl.seguro') }}
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ cotizadorCarsResultSelect.tarifaSeguro | currency }}
                  </span>
                </div>
                <div
                  v-for="(suplemento, indSupl) in cotizadorCarsResultSelect.suplementos"
                  :key="indSupl"
                  class="d-flex justify-space-between align-center ml-2"
                >
                  <fragment v-if="!!suplemento.online && !!suplemento.check">
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ suplemento.suplemento.name }}
                    </span>
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ suplemento.priceAll | currency }}
                    </span>
                  </fragment>
                </div>
              </fragment>

              <fragment v-if="precioSuplementPayRentator > 0">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="my-1">
                    {{ $t('lbl.payRent') }}
                  </h4>
                  <h4>
                    {{ precioSuplementPayRentator | currency }}
                  </h4>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ $t('lbl.depositoGarantia') }}
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ cotizadorCarsResultSelect.tarifaGarantia | currency }}
                  </span>
                </div>
                <div
                  v-for="(suplemento, indSupl) in cotizadorCarsResultSelect.suplementos"
                  :key="indSupl"
                  class="d-flex justify-space-between align-center ml-2"
                >
                  <fragment v-if="!suplemento.online && !!suplemento.check">
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ suplemento.suplemento.name }}
                    </span>
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ suplemento.priceAll | currency }}
                    </span>
                  </fragment>
                </div>
              </fragment>

              --<div class="d-flex justify-space-between align-center ml-2">
                <h6>
                  {{ $t('lbl.seguro') }}
                </h6>
                <h6>
                  {{ cotizadorCarsResultSelect.tarifaSeguro | currency }}
                </h6>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <h6>
                  {{ $t('lbl.depositoGarantia') }}
                </h6>
                <h6>
                  {{ cotizadorCarsResultSelect.tarifaGarantia | currency }}
                </h6>
              </div>
              <fragment>
                <div class="d-flex justify-space-between align-center ml-2 mb-2">
                  <h6>
                    {{ $t('lbl.tarifaRetorno') }}
                  </h6>
                  <h6>
                    {{ precioTarifaRetorno | currency }}
                  </h6>
                </div>
              </fragment>--
            </v-card>-->

            <v-card
              width="100%"
              class="mx-2 mt-3 pa-4 pt-0"
            >
              <div class="d-flex justify-space-between align-center">
                <h3 class="my-2">
                  {{ $t('lbl.totalPay') }}
                </h3>
                <h3>
                  <h4>
                    {{ precioTotal | currency(currencySymbolFacturar) }}
                  </h4>
                </h3>
              </div>
              <fragment v-if="precioSuplementPayOnline > 0">
                <div class="d-flex justify-space-between align-center">
                  <!--<h4 class="my-1">
                    {{ $t('lbl.payOnline') }}
                  </h4>
                  <h4>
                    {{ (precioSuplementPayOnline + cotizadorCarsResultSelect.priceTotal) | currency }}
                  </h4>-->
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  > {{ $t('lbl.payOnline') }} </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ (precioSuplementPayOnline + cotizadorCarsResultSelect.priceTotal) | currency(currencySymbolFacturar) }}
                  </span>
                </div>
                <!--<div class="d-flex justify-space-between align-center ml-2">
                  <fragment>
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ $t('lbl.tarif') }}
                    </span>
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ cotizadorCarsResultSelect.priceTotal | currency }}
                    </span>
                  </fragment>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <fragment>
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ $t('lbl.cargoSuplement') }}
                    </span>
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ precioSuplementPayOnline | currency }}
                    </span>
                  </fragment>
                </div>-->
              </fragment>

              <fragment v-if="precioSuplementPayRentator > 0">
                <div class="d-flex justify-space-between align-center">
                  <!--<h4 class="my-1">
                    {{ $t('lbl.payRent') }}
                  </h4>
                  <h4>
                    {{ precioSuplementPayRentator | currency }}
                  </h4>-->
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ $t('lbl.payRent') }}
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ precioSuplementPayRentator | currency(currencySymbolFacturar) }}
                  </span>
                </div>
                <!--<div class="d-flex justify-space-between align-center ml-2">
                  <fragment>
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ $t('lbl.cargoSuplement') }}
                    </span>
                    <span
                      class="pb-0 mb-0 pt-0 mt-0"
                      style="font-size: 12px"
                    >
                      {{ precioSuplementPayRentator | currency }}
                    </span>
                  </fragment>
                </div>-->
              </fragment>
            </v-card>

            <v-card
              width="100%"
              class="mx-2 mt-3 pa-4 pt-0"
            >
              <v-card-title
                primary-title
                class="px-0 py-2"
              >
                {{ $t('lbl.otherCarge') }}
              </v-card-title>
              <fragment v-if="precioSuplementPayRentator > 0">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="my-1">
                    {{ $t('lbl.payRent') }}
                  </h4>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ $t('lbl.dayExtra') }} (x {{ $t('lbl.dia') }})
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ cotizadorCarsResultSelect.tarifaDayExtra | currency(currencySymbolFacturar) }}
                  </span>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  > {{ $t('lbl.tarifaRetorno') }} (x km) </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ 0 | currency(currencySymbolFacturar) }}
                  </span>
                </div>
              </fragment>
            </v-card>
          </v-row>
        </fragment>

        <!--MOSTRAR PARA EL CLIENTE-->
        <v-row>
          <fragment v-if="!showOnlyGestor">
            <v-card
              width="100%"
              class="mx-2 pa-4"
            >
              <v-row>
                <v-col
                  cols="4"
                  class="pt-2"
                >
                  <h1
                    primary-title
                    class="pa-0"
                  >
                    PVP
                  </h1>
                </v-col>
                <v-col
                  cols="8"
                  class="pt-5 text-end"
                >
                  <AppBarCurrencyPVP />
                </v-col>
                <v-col
                  cols="12"
                  class="mt-0 pt-0"
                >
                  <div class="d-flex justify-space-between align-center">
                    <h4 class="mb-4">
                      {{ $t('lbl.tarif') }}
                    </h4>
                    <h4 class="mb-4">
                      <h4>
                        {{ cotizadorCarsResultSelect.priceTotal | currency(currencySymbolFacturar) }}
                      </h4>
                    </h4>
                  </div>
                </v-col>
              </v-row>
              <div class="d-flex justify-space-between align-center">
                <h4>
                  {{ $t('lbl.cargoSuplement') }}
                </h4>
                <h4>
                  <h4>
                    {{ (precioSuplementPayOnline + precioSuplementPayRentator) | currency(currencySymbolFacturar) }}
                  </h4>
                </h4>
              </div>
              <!---->
              <div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ $t('lbl.seguro') }}
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ cotizadorCarsResultSelect.tarifaSeguro | currency(currencySymbolFacturar) }}
                </span>
              </div>
              <div
                v-for="(suplemento, indSupl) in cotizadorCarsResultSelect.suplementos"
                :key="Math.random() * (indSupl + 1)"
                class="d-flex justify-space-between align-center ml-2"
              >
                <fragment
                  v-if="
                    suplemento.suplemento.slug.toLowerCase() !== 'tarifa-de-retorno' &&
                      suplemento.check === true &&
                      suplemento.priceAll > 0
                  "
                >
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ suplemento.suplemento.name }}
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ suplemento.priceAll | currency(currencySymbolFacturar) }}
                  </span>
                </fragment>
              </div>
              <div class="d-flex justify-space-between align-center ml-2">
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ $t('lbl.depositoGarantia') }}
                </span>
                <span
                  class="pb-0 mb-0 pt-0 mt-0"
                  style="font-size: 12px"
                >
                  {{ cotizadorCarsResultSelect.tarifaGarantia | currency(currencySymbolFacturar) }}
                </span>
              </div>
              <fragment v-if="precioTarifaRetorno > 0">
                <div class="d-flex justify-space-between align-center ml-2">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ $t('lbl.tarifaRetorno') }}
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ precioTarifaRetorno | currency(currencySymbolFacturar) }}
                  </span>
                </div>
              </fragment>
            </v-card>
            <v-card
              width="100%"
              class="mx-2 mt-3 pa-4 pt-0"
            >
              <div class="d-flex justify-space-between align-center">
                <h3 class="my-2">
                  {{ $t('lbl.totalPay') }}
                </h3>
                <h3>
                  <h4>
                    {{ precioTotal | currency(currencySymbolFacturar) }}
                  </h4>
                </h3>
              </div>
              <fragment v-if="precioSuplementPayOnline > 0">
                <div class="d-flex justify-space-between align-center">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  > {{ $t('lbl.payOnline') }} </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ (precioSuplementPayOnline + cotizadorCarsResultSelect.priceTotal) | currency(currencySymbolFacturar) }}
                  </span>
                </div>
              </fragment>

              <fragment v-if="precioSuplementPayRentator > 0">
                <div class="d-flex justify-space-between align-center">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ $t('lbl.payRent') }}
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ precioSuplementPayRentator | currency(currencySymbolFacturar) }}
                  </span>
                </div>
              </fragment>
            </v-card>
            <v-card
              width="100%"
              class="mx-2 mt-3 pa-4 pt-0"
            >
              <v-card-title
                primary-title
                class="px-0 py-2"
              >
                {{ $t('lbl.otherCarge') }}
              </v-card-title>
              <fragment v-if="precioSuplementPayRentator > 0">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="my-1">
                    {{ $t('lbl.payRent') }}
                  </h4>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ $t('lbl.dayExtra') }} (x {{ $t('lbl.dia') }})
                  </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ cotizadorCarsResultSelect.tarifaDayExtra | currency(currencySymbolFacturar) }}
                  </span>
                </div>
                <div class="d-flex justify-space-between align-center ml-2">
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  > {{ $t('lbl.tarifaRetorno') }} (x km) </span>
                  <span
                    class="pb-0 mb-0 pt-0 mt-0"
                    style="font-size: 12px"
                  >
                    {{ 0 | currency(currencySymbolFacturar) }}
                  </span>
                </div>
              </fragment>
            </v-card>
          </fragment>

          <!--BOTONERA-->
          <v-card
            width="100%"
            class="mx-2 mt-3 pa-4 pt-0"
          >
            <v-container grid-list-xs>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    color="primary"
                    width="100%"
                    block
                    :disabled="declineTerms || !driverAgeCumple"
                    :loading="loadingCart"
                    @click="addCarItem"
                  >
                    <v-icon class="mr-4">
                      {{ icons.mdiCartOutline }}
                    </v-icon>
                    {{ $t('btn.addToCar') }}
                  </v-btn>
                  <v-row>
                    <v-col
                      cols="12"
                      class="pt-5 text-end"
                    >
                      <AppBarCurrencyPVP />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    color="primary"
                    width="100%"
                    outlined
                    @click="printItem"
                  >
                    {{ $t('btn.printCotization') }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    color="primary"
                    width="100%"
                    outlined
                    @click="sendItem"
                  >
                    {{ $t('btn.sendCotization') }}
                  </v-btn>
                </v-col>
                <!--<v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    color="primary"
                    width="100%"
                    :disabled="declineTerms || !driverAgeCumple"
                    :loading="loading"
                    @click="reserveNow"
                  >
                    <v-icon class="mr-4">
                      {{ icons.mdiClipboardCheckOutline }}
                    </v-icon>
                    {{ $t('btn.soliciteReserve') }}
                  </v-btn>
                </v-col>-->
              </v-row>
            </v-container>
          </v-card>
        </v-row>
      </template>
      <v-row
        v-if="isLoading"
        class="my-2"
      >
        <v-card
          width="100%"
          class="mx-2 pa-4 pt-0"
        >
          <div class="align-center">
            <v-skeleton-loader
              v-for="(i, ind) in 4"
              :key="ind"
              class="mx-auto"
              type="table-tfoot"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto"
              type="actions"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mx-auto"
              type="actions"
            ></v-skeleton-loader>
          </div>
        </v-card>
      </v-row>
    </v-col>

    <!-- MOSTRAR PRINT ITEMS MORE ONE -->
    <v-dialog
      v-model="showModalPrint"
      scrollable
      max-width="480px"
    >
      <v-card max-height="480px">
        <v-card-title>{{ `${$t('btn.print')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <h3>{{ $t('lbl.titlePrintCar') }}</h3>
          <br />
          <v-radio-group
            v-model="printCotiItems"
            row
            hide-details
            class="mt-0"
          >
            <v-radio
              :label="$t('lbl.printCar1')"
              value="actual"
            ></v-radio>
            <v-radio
              :label="$t('lbl.printCar2')"
              value="all"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="showModalPrint = !showModalPrint"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :disabled="!printCotiItems"
            @click="confirmPrint"
          >
            {{ $t('btn.print') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR SEND ITEMS MORE ONE -->
    <v-dialog
      v-model="showModalSend"
      scrollable
      max-width="480px"
    >
      <v-card max-height="480px">
        <v-card-title>{{ `${$t('lbl.sendCotization')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <h3>{{ $t('lbl.titleSendCar') }}</h3>
          <br />
          <v-radio-group
            v-model="printCotiItems"
            row
            hide-details
            class="mt-0"
          >
            <v-radio
              :label="$t('lbl.printCar1')"
              value="actual"
            ></v-radio>
            <v-radio
              :label="$t('lbl.printCar2')"
              value="all"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="showModalSend = !showModalSend"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :disabled="!printCotiItems"
            @click="confirmSend"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SidebarDataClient
      v-if="isSidebarDataClientActive"
      v-model="isSidebarDataClientActive"
      :from="btnName"
      :ctas-bancarias="ctasBancarias"
      @confirmPrintCotization="confirmPrintCotization"
      @confirmSend="confirmSend"
    />
    <SidebarSendCotization
      v-if="isSidebarSendCotizationActive"
      v-model="isSidebarSendCotizationActive"
      :name-company="nameCompany"
      :name-user="nameUser"
      :name-b2b="nameB2b"
      :email-user="emailUser"
      :user="user"
      @confirmSendCotization="confirmSendCotization"
      @showReturnDataClient="showReturnDataClient"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronLeft,
  mdiBagSuitcase,
  mdiBagPersonal,
  mdiInformation,
  mdiCartOutline,
  mdiClipboardCheckOutline,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import AppBarCurrencyPVP from '@/@core/layouts/components/app-bar/AppBarCurrencyPVP.vue'
import { v4 as uuidv4 } from 'uuid'
import SidebarDataClient from './SidebarDataClient.vue'
import SidebarSendCotization from './SidebarSendCotization.vue'

export default {
  components: {
    SidebarDataClient,
    SidebarSendCotization,
    AppBarCurrencyPVP,
  },
  data() {
    return {
      nameB2b: null,
      nameCompany: '',
      nameUser: '',
      emailUser: '',
      btnName: '',
      isSidebarDataClientActive: false,
      isSidebarSendCotizationActive: false,
      printCotiItems: null,
      itemsOrigen: [],
      isLoading: true,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      vrad: 0,
      markup: null,
      valueMarkup: null,
      descuento: null,
      valueDescuento: null,
      agregarDescuento: false,
      incluyeComition: 0,
      incluyeDescuento: 0,
      showOnlyGestor: true,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronLeft,
        mdiBagSuitcase,
        mdiBagPersonal,
        mdiInformation,
        mdiCartOutline,
        mdiClipboardCheckOutline,
      },
      precioWithMUP: 0,
      precioWithDESC: 0,
      loadingMarkups: true,
      permisos: sessionStorage.getItem('permisos_auth'),
      user: null,
      showModalPrint: false,
      showModalSend: false,
      textRequiered: [v => !!v || 'Campo requerido'],
      ctasBancarias: [],
      currencyPVP: localStorage.getItem('currencyPVP'),
      currencyCodePVP: localStorage.getItem('currencyCodePVP'),
      markOrigin: JSON.parse(localStorage.getItem('markupsOrigin')),
      loadingCart: false,
    }
  },
  computed: {
    ...mapState({
      carItems: state => state.app.carItems,
      driverAgeCumple: state => state.app.driverAgeCumple,
      declineTerms: state => state.app.declineTerms,
      cotizadorCars: state => state.app.cotizadorCars,
      cotizadorCarsResult: state => state.app.cotizadorCarsResult,
      cotizadorCarsResultSelect: state => state.app.cotizadorCarsResultSelect,
      cotizadorCarsSelectMarkups: state => state.app.cotizadorCarsSelectMarkups,
      cotizadorPaxCarSelect: state => state.app.cotizadorPaxCarSelect,
      noDocumentConductorPrincipalScanner: state => state.app.noDocumentConductorPrincipalScanner,
      noDocumentConductorAditionalScanner: state => state.app.noDocumentConductorAditionalScanner,
      clientCarShop: state => state.app.clientCarShop,
      currency_code: state => state.app.currency_code,
      currency_symbol: state => state.app.currency_symbol,
      tasaCambioContrate: state => state.app.tasaCambioContrate,
      tasaCambioFacturar: state => state.app.tasaCambioFacturar,
      currencySymbolFacturar: state => state.app.currencySymbolFacturar,
      currencyCodeFacturar: state => state.app.currencyCodeFacturar,
    }),
    precioNeto() {
      return this.cotizadorCarsResultSelect.tarifaAllDayNeto
    },
    precioInitial() {
      const priceTotal = (this.cotizadorCarsResultSelect.tarifaAllDayNeto / this.tasaCambioContrate) * this.tasaCambioFacturar

      return priceTotal
    },
    valueMarkupCalculate() {
      let priceTotal = this.precioInitial

      if (this.markup) {
        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          const v = (parseFloat(priceTotal) * parseFloat(this.markup)) / 100
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) + v
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          priceTotal = parseFloat(priceTotal) + parseFloat(this.markup)
        }
      }

      return priceTotal - this.precioInitial
    },
    valueDescuentoCalculate() {
      let priceTotal = this.precioWithMarkup
      if (this.descuento) {
        // %
        if (this.valueDescuento === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          priceTotal = this.precioWithMarkup - this.precioWithDescuento

          // this.priceDescuento = priceTotal - this.precioInitial - ((this.cotizadorHotelsResultSelect.minPrice * parseFloat(this.descuento)) / 100)
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          priceTotal = parseFloat(this.descuento)
        }
      } else {
        priceTotal = 0
      }

      return priceTotal
    },
    precioWithMarkup() {
      let priceTotal = this.precioInitial

      if (this.markup) {
        sessionStorage.setItem('markup', this.markup)
        sessionStorage.setItem('valueMarkup', this.valueMarkup)

        // %
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = Math.ceil((parseFloat(priceTotal) * parseFloat(this.markup)) / 100)
          // eslint-disable-next-line no-mixed-operators
          priceTotal = Math.ceil(parseFloat(priceTotal) + this.precioWithMUP)
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithMUP = Math.ceil(parseFloat(this.markup))
          priceTotal = parseFloat(priceTotal) + parseFloat(this.markup)

          // priceTotal = Math.ceil(parseFloat(priceTotal) + parseFloat(this.markup))
        }
      }

      return priceTotal
    },
    precioWithDescuento() {
      let priceTotal = this.precioWithMarkup

      if (this.descuento) {
        // %
        if (this.valueDescuento === '%') {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = this.precioInitial * parseFloat(this.descuento) / 100

          // this.incluyeDescuento = (this.cotizadorCarsResultSelect.tarifaAllDay * parseFloat(this.descuento)) / 100

          // this.incluyeDescuento = (parseFloat(priceTotal) * parseFloat(this.descuento)) / 100
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithDESC = this.incluyeDescuento
          // eslint-disable-next-line no-mixed-operators
          priceTotal = parseFloat(priceTotal) - this.incluyeDescuento
        } else {
          // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
          this.incluyeDescuento = parseFloat(this.descuento)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.precioWithDESC = this.incluyeDescuento
          priceTotal = parseFloat(priceTotal) - this.incluyeDescuento
        }
      }

      return priceTotal
    },
    precioTotal() {
      let priceTotal = this.cotizadorCarsResultSelect.priceTotal
        + parseFloat(this.cotizadorCarsResultSelect.tarifaSeguro)
        + parseFloat(this.cotizadorCarsResultSelect.tarifaGarantia)

      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (suplement.check) {
          priceTotal += suplement.priceAll
        }
      })

      return priceTotal
    },
    precioTarifaRetorno() {
      let priceTotal = 0

      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (suplement.suplemento.slug === 'tarifa-de-retorno' && suplement.check) {
          priceTotal = suplement.priceAll
        }
      })

      return priceTotal
    },
    precioSuplementPayOnline() {
      let priceTotal = parseFloat(this.cotizadorCarsResultSelect.tarifaSeguro)

      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !!suplement.online) {
          priceTotal += suplement.priceAll
        }
      })

      return priceTotal
    },
    precioSuplementPayRentator() {
      let priceTotal = parseFloat(this.cotizadorCarsResultSelect.tarifaGarantia)

      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !suplement.online) {
          priceTotal += suplement.priceAll
        }
      })

      return priceTotal
    },

    generateJsonCar() {
      const suplementos = []
      this.cotizadorCarsResultSelect.suplementos.forEach(element => {
        if (element.check) {
          suplementos.push(element)
        }
      })

      // PAY TOTAL
      let priceTotal = this.cotizadorCarsResultSelect.priceTotal
        + parseFloat(this.cotizadorCarsResultSelect.tarifaSeguro)
        + parseFloat(this.cotizadorCarsResultSelect.tarifaGarantia)

      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (suplement.check) {
          priceTotal += suplement.priceAll
        }
      })
      const jsonItem = {
        car: this.cotizadorCarsResultSelect.car,
        category_id: this.cotizadorCarsResultSelect.category_id,
        marca_id: this.cotizadorCarsResultSelect.marca_id,
        contrate_id: this.cotizadorCarsResultSelect.contrate_id,
        cuposDisponibles: this.cotizadorCarsResultSelect.cuposDisponibles,
        diasReservar: this.cotizadorCarsResultSelect.diasReservar,
        priceTotal,

        // priceTotal: this.cotizadorCarsResultSelect.priceTotal,
        priceWithDescuento: this.cotizadorCarsResultSelect.priceWithDescuento,
        priceWithMarkup: this.cotizadorCarsResultSelect.priceWithMarkup,
        rentadora: this.cotizadorCarsResultSelect.rentadora,
        tarifaAllDay: this.cotizadorCarsResultSelect.tarifaAllDay,
        tarifaDay: this.cotizadorCarsResultSelect.tarifaDay,
        tarifaDayExtra: this.cotizadorCarsResultSelect.tarifaDayExtra,
        tarifaGarantia: this.cotizadorCarsResultSelect.tarifaGarantia,
        tarifaSeguro: this.cotizadorCarsResultSelect.tarifaSeguro,
        suplementos,

        // NEW
        priceNeto: this.precioNeto,
        contrateCurrencySymbol: this.cotizadorCarsResultSelect.contrate_currency_symbol,
        contrateCurrencyCode: this.cotizadorCarsResultSelect.contrate_currency_code,
        currencyBaseCode: this.cotizadorCarsResultSelect.currencyBaseCode,
        tasaCambioContrate: this.tasaCambioContrate,
        tasaCambioFacturar: this.tasaCambioFacturar,
        currencySymbolFacturar: this.currencySymbolFacturar,
        currencyCodeFacturar: this.currencyCodeFacturar,

        search: this.cotizadorCars,
      }

      let recogida
      if (this.cotizadorCars.recogida.type === 'oficina') {
        recogida = this.cotizadorCars.recogida
      } else if (this.cotizadorCars.recogida.type === 'destino') {
        recogida = this.cotizadorCarsResultSelect.recogida
      }

      let entrega
      if (this.cotizadorCars.entrega) {
        if (this.cotizadorCars.entrega.type === 'oficina') {
          entrega = this.cotizadorCars.entrega
        } else if (this.cotizadorCars.entrega.type === 'destino') {
          entrega = this.cotizadorCarsResultSelect.entrega
        }
      } else {
        entrega = recogida
      }

      const jsonReserve = {
        entrega,
        dateEntrega: this.cotizadorCars.dateEntrega,
        recogida,
        dateRecogida: this.cotizadorCars.dateRecogida,
        entregaDiffRecogida: this.cotizadorCars.entregaDiffRecogida,
        items: jsonItem,
      }

      let payOnline = parseFloat(this.cotizadorCarsResultSelect.tarifaSeguro) + parseFloat(this.cotizadorCarsResultSelect.priceTotal)
      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !!suplement.online) {
          payOnline += suplement.priceAll
        }
      })

      let payRentadora = parseFloat(this.cotizadorCarsResultSelect.tarifaGarantia)
      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (!!suplement.check && !suplement.online) {
          payRentadora += suplement.priceAll
        }
      })

      let payRetorno = 0
      this.cotizadorCarsResultSelect.suplementos.forEach(suplement => {
        if (suplement.suplemento.slug === 'tarifa-de-retorno' && suplement.check) {
          payRetorno = suplement.priceAll
        }
      })
      const jsonPrices = {
        priceTotal,
        priceTarifa: this.cotizadorCarsResultSelect.priceTotal,
        precioSuplementPayOnline: payOnline,
        pricePayOnline: payOnline,
        precioSuplementPayRentator: payRentadora,
        precioTarifaRetorno: payRetorno,
        precioTarifaxKm: 0,
        diasReservar: this.cotizadorCarsResultSelect.diasReservar,
        edad: this.cotizadorCarsResultSelect.edad,
        edad_max: this.cotizadorCarsResultSelect.edad_max,
        conditions_id: this.cotizadorCarsResultSelect.conditions,
        contrate_id: this.cotizadorCarsResultSelect.contrate_id,
        proveedor_id: this.cotizadorCarsResultSelect.proveedor_id,
        markups: this.cotizadorCarsResultSelect.markups,
        descuento: this.cotizadorCarsResultSelect.priceWithDescuento,
        descuento_v: this.descuento,
        descuento_value: this.valueDescuento,
        markup: this.cotizadorCarsResultSelect.priceWithMarkup,
        markup_v: this.markup,
        markup_value: this.valueMarkup,
        suplementos,
        tarifaAllNeto: this.cotizadorCarsResultSelect.tarifaAllDay,
        tarifaDayNeto: this.cotizadorCarsResultSelect.tarifaDay,
        tarifaDayExtra: this.cotizadorCarsResultSelect.tarifaDayExtra,
        tarifaGarantia: this.cotizadorCarsResultSelect.tarifaGarantia,
        tarifaSeguro: this.cotizadorCarsResultSelect.tarifaSeguro,

        // NEW
        priceNeto: this.precioNeto,
        contrateCurrencySymbol: this.cotizadorCarsResultSelect.contrate_currency_symbol,
        contrateCurrencyCode: this.cotizadorCarsResultSelect.contrate_currency_code,
        currencyBaseCode: this.cotizadorCarsResultSelect.currencyBaseCode,
        tasaCambioContrate: this.tasaCambioContrate,
        tasaCambioFacturar: this.tasaCambioFacturar,
        currencySymbolFacturar: this.currencySymbolFacturar,
        currencyCodeFacturar: this.currencyCodeFacturar,
      }

      const json = {
        check: true,
        id: uuidv4(),
        product: 'cars',
        data_client: this.cotizadorPaxCarSelect,
        data_markups: jsonPrices,
        data_service: jsonReserve,
        data_reserva: {},
        lang: this.$i18n.locale,
        user_id: this.user.id,
      }

      return json
    },
  },
  created() {
    this.profile()

    let mark = {
      markup: 0,
      valueMarkup: 0,
      descuento: 0,
      valueDescuento: 0,
    }
    if (this.cotizadorCarsResultSelect.markups) {
      this.valueMarkup = '%'
      if (!this.cotizadorCarsResultSelect.markups.is_pc) {
        if (this.cotizadorCarsResultSelect.markups.markup > 0) {
          this.valueMarkup = '$'
        } else {
          this.valueMarkup = null
        }
      }

      if (this.cotizadorCarsResultSelect.markups.markup && this.valueMarkup === '%') {
        this.markup = this.cotizadorCarsResultSelect.markups.markup
      } else if (this.cotizadorCarsResultSelect.markups.markup && this.valueMarkup === '$') {
        this.markup = this.cotizadorCarsResultSelect.markups.markup * this.cotizadorCarsResultSelect.diasReservar
      }
      mark = {
        markup: this.markup,
        valueMarkup: this.valueMarkup,
        descuento: 0,
        valueDescuento: 0,
      }

      this.applyMarkups()

      setTimeout(() => {
        if (this.valueMarkup === '%') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.cotizadorCarsResultSelect.priceWithMarkup = Math.ceil(
            (parseFloat(this.cotizadorCarsResultSelect.tarifaAllDay) * parseFloat(this.markup)) / 100,
          )
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.cotizadorCarsResultSelect.priceWithMarkup = Math.ceil(parseFloat(this.markup))
        }
      }, 100)
    }

    this.setCotizadorCarsSelectMarkups(mark)

    this.clearCotizadorPaxCarSelect()

    // PARA FIJAR LA COLUMNA D LA DERECHA
    // window.addEventListener('scroll', this.handleScroll)
    if (this.permisos.includes('pvp:list')) {
      window.addEventListener('keydown', e => {
        if ((e.key === 'r' || e.key === 'R') && e.altKey) {
          this.changePrice()
        }
      })
    } else {
      this.changePrice()
    }
  },

  /* destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }, */
  methods: {
    ...mapMutations([
      'setCotizadorCarsSelectMarkups',
      'setCotizadorCarSelectPrice',
      'adicionarItemToCar',
      'setCotizadorCarsResult',
      'setSearchingCar',
      'setCotizadorCars',
      'setCotizadorCarsResultSelect',
      'setCotizadorPaxCarSelect',
      'updateLoadingBtn',
    ]),
    validateMarkupsInput() {
      const sending = this.verificarMarkupsOrigin()
      if (!sending) {
        this.$toast.error(this.$t('msg.markupsNoValid'))

        // RESTAURAR MARKUPS
        this.markup = this.markOrigin.markup
        if (this.markOrigin.is_pc) {
          this.valueMarkup = '%'
        } else {
          this.valueMarkup = this.currencyCodeFacturar
        }
      }
    },
    validateMarkupsRadio() {
      const sending = this.verificarMarkupsOrigin()
      if (!sending) {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      }
    },
    validateDescuentoInput() {
      if (this.valueMarkupCalculate < this.valueDescuentoCalculate) {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      }
    },
    changeAgregarDescuento(e) {
      if (e === null) {
        this.descuento = null
        this.valueDescuento = null
      } else {
        this.descuento = 0
        this.valueDescuento = '%'
      }
    },
    verificarMarkupsOrigin() {
      // const markOrigin = JSON.parse(localStorage.getItem('markupsOrigin'))
      let result = true

      if (this.valueMarkup === '%') {
        if (this.markOrigin.is_pc) {
          if (this.markOrigin.markup > this.markup) {
            result = false
          }
        }
      } else {
        // RECALCULAR EL MARKUPS
        // eslint-disable-next-line no-mixed-operators
        const newValue = parseInt((this.precioInitial * this.markOrigin.markup / 100), 10)
        if (newValue > this.markup) {
          result = false
        }
      }

      return result
    },

    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else {
            this.user = response.data.data
            this.nameCompany = this.user.company.name
            this.nameUser = `${this.user.name} ${this.user.apellidos}`
            this.emailUser = `${this.user.email}`
          }
        })
        .finally(() => {
          this.getConfigData()
        })
    },
    getConfigData() {
      this.axios
        .get('configuration/data-general', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.nameB2b = res.data.data.data.data.name
            if (res.data.data.data.data.ctas_bancarias) {
              this.ctasBancarias = res.data.data.data.data.ctas_bancarias
            }
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleScroll() {
      if (window.scrollY >= 60) {
        this.$refs.statiCard.classList.add('topMine')
      } else {
        this.$refs.statiCard.classList.remove('topMine')
      }
    },
    goBack() {
      this.$router.push({ name: 'cotizador' })
    },
    changePrice() {
      this.showOnlyGestor = !this.showOnlyGestor
    },
    applyMarkups() {
      sessionStorage.setItem('markup', this.markup)
      sessionStorage.setItem('valueMarkup', this.valueMarkup)
      sessionStorage.setItem('descuento', this.descuento)
      sessionStorage.setItem('valueDescuento', this.valueDescuento)

      const sending = this.verificarMarkupsOrigin()
      if (!sending) {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      } else if (parseInt(this.precioInitial, 10) <= parseInt(this.precioWithDescuento, 10)) {
        this.setCotizadorCarsSelectMarkups({
          markup: this.markup,
          valueMarkup: this.valueMarkup,
          descuento: this.descuento,
          valueDescuento: this.valueDescuento,
        })

        this.setCotizadorCarSelectPrice({
          priceTotal: this.precioWithDescuento,
          priceWithMarkup: this.precioWithMUP,
          priceWithDescuento: this.precioWithDESC,
        })
      } else {
        this.$toast.error(this.$t('msg.markupsNoValid'))
      }
    },

    reserveNow() {
      let procesar = true
      if (
        !this.cotizadorPaxCarSelect.nameConductorPrincipal
        || !this.cotizadorPaxCarSelect.apellidosConductorPrincipal
        || !this.cotizadorPaxCarSelect.birthdayConductorPrincipal
        || !this.cotizadorPaxCarSelect.nationalityConductorPrincipal
        || !this.cotizadorPaxCarSelect.noDocumentConductorPrincipal
      ) {
        procesar = false
      }

      if (this.cotizadorPaxCarSelect.conductorAditional) {
        if (
          !this.cotizadorPaxCarSelect.nameConductorAditional
          || !this.cotizadorPaxCarSelect.apellidosConductorAditional
          || !this.cotizadorPaxCarSelect.birthdayConductorAditional
          || !this.cotizadorPaxCarSelect.nationalityConductorAditional
          || !this.cotizadorPaxCarSelect.noDocumentConductorAditional
        ) {
          procesar = false
        }
      }

      if (procesar) {
        this.loading = true

        const json = this.jsonItem

        const formData = new FormData()
        if (this.noDocumentConductorPrincipalScanner) {
          formData.append('noDocumentConductorPrincipalScanner', this.noDocumentConductorPrincipalScanner)
        }
        if (this.noDocumentConductorAditionalScanner) {
          formData.append('noDocumentConductorAditionalScanner', this.noDocumentConductorAditionalScanner)
        }

        formData.append('data', JSON.stringify(json))

        this.axios
          .post(
            'reservations/generate',
            formData,
            { responseType: 'arraybuffer' },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            let filename = 'cotization'
            filename = `${this.cotizadorPaxCarSelect.nameConductorPrincipal} ${
              this.cotizadorPaxCarSelect.apellidosConductorPrincipal
            }_Autos_${this.$moment().format('M-D-Y')}`
            filename = filename.replace(/ /g, '-')

            this.downloadFile(res, filename)

            // PARA OBTENER EL ID DE LA ULTIMA RESERVA DEL GESTOR
            this.axios
              .get(`reservations/last-user/${this.user.id}`, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(reserv => {
                sessionStorage.setItem('reservations-id', reserv.data.data.data.id)
              })
              .finally(() => {
                this.$router.push({ name: 'success-cotitation' })
              })

            // this.$router.push({ name: 'success-cotitation' })
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
        this.loading = false
      }
    },
    downloadFile(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },

    addCarItem() {
      let procesar = true
      if (
        !this.cotizadorPaxCarSelect.nameConductorPrincipal
        || !this.cotizadorPaxCarSelect.apellidosConductorPrincipal
        || !this.cotizadorPaxCarSelect.birthdayConductorPrincipal
        || !this.cotizadorPaxCarSelect.nationalityConductorPrincipal
        || !this.cotizadorPaxCarSelect.noDocumentConductorPrincipal
      ) {
        procesar = false
      }

      if (this.cotizadorPaxCarSelect.conductorAditional) {
        if (
          !this.cotizadorPaxCarSelect.nameConductorAditional
          || !this.cotizadorPaxCarSelect.apellidosConductorAditional
          || !this.cotizadorPaxCarSelect.birthdayConductorAditional
          || !this.cotizadorPaxCarSelect.nationalityConductorAditional
          || !this.cotizadorPaxCarSelect.noDocumentConductorAditional
        ) {
          procesar = false
        }
      }

      if (procesar) {
        this.loadingCart = true
        const json = this.generateJsonCar

        /* const formData = new FormData()
        if (this.noDocumentConductorPrincipalScanner) {
          formData.append('noDocumentConductorPrincipalScanner', this.noDocumentConductorPrincipalScanner)
        }
        if (this.noDocumentConductorAditionalScanner) {
          formData.append('noDocumentConductorAditionalScanner', this.noDocumentConductorAditionalScanner)
        }
        formData.append('data', JSON.stringify(json)) */

        /* const cars = localStorage.getItem('car-shop') ? JSON.parse(localStorage.getItem('car-shop')) : []
        cars.push(json)

        localStorage.setItem('car-shop', JSON.stringify(cars))
        this.adicionarItemToCar(json)

        // LIMPIAR COTIZADOR
        this.clearCotizador('addToCard') */

        // CART EN BD
        this.axios
          .post('cart/add', { item: json }, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success) {
              // LIMPIAR COTIZADOR
              this.clearCotizador('addToCard')
            }
          })
          .finally(() => {
            this.loadingCart = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    clearCotizador(action) {
      if (action === 'print') {
        // LLEVARLO A LA VISTA DE PRINT
        setTimeout(() => {
          this.$router.push({ name: 'view-details' })
        }, 200)
      } else {
        // REGRESARLO AL COTIZADOR
        setTimeout(() => {
          this.goBack()
        }, 200)
      }

      sessionStorage.setItem('clear-cotization', 'cars')
      this.setCotizadorCarsResult([])
      setTimeout(() => {
        this.setCotizadorCars({
          recogida: null,
          entrega: null,
          entregaDiffRecogida: false,
          dateRecogida: null,
          dateEntrega: null,
          transmision_id: null,
          rentadora_id: null,
        })
        this.setCotizadorCarsResultSelect([])
        this.clearCotizadorPaxCarSelect()

        this.setSearchingCar(false)

        this.emptyClientCarShop()
        this.updateItemToCar([])
        localStorage.removeItem('car-shop')
      }, 100)
    },

    clearCotizadorPaxCarSelect() {
      this.setCotizadorPaxCarSelect({
        nameConductorPrincipal: null,
        apellidosConductorPrincipal: null,
        nameSecoundConductorPrincipal: null,
        birthdayConductorPrincipal: null,
        nationalityConductorPrincipal: null,
        sexConductorPrincipal: null,
        noDocumentConductorPrincipal: null,
        noDocumentConductorPrincipalScanner: null,
        conductorAditional: false,
        nameConductorAditional: null,
        apellidosConductorAditional: null,
        nameSecoundConductorAditional: null,
        birthdayConductorAditional: null,
        nationalityConductorAditional: null,
        sexConductorAditional: null,
        noDocumentConductorAditional: null,
        noDocumentConductorAditionalScanner: null,
      })
    },

    printItem() {
      if (this.carItems.length > 1) {
        this.showModalPrint = true
      } else {
        this.printCotiItems = 'actual'
        this.confirmPrint()
      }
    },
    confirmPrint() {
      this.showModalPrint = false
      this.btnName = 'btn-print'
      setTimeout(() => {
        this.isSidebarDataClientActive = true
      }, 50)

      /* if (this.printCotiItems === 'all') {
        // this.$router.push({ name: 'car-shop-items' })
      } else {
      } */
    },
    confirmPrintCotization() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        const procesar = true
        this.updateLoadingBtn(true)
        if (procesar) {
          const jsonCar = {
            items: [this.generateJsonCar],
            data_client: this.clientCarShop,
            action: 'print',
          }

          this.axios
            .post('reservations/generate-cotization', jsonCar, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                sessionStorage.setItem('code-cotiza', res.data.data.code)
                sessionStorage.setItem('action-cotiza', 'print')

                // LIMPIAR COTIZADOR
                this.clearCotizador('print')
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.updateLoadingBtn(false)
              this.isSidebarDataClientActive = false
            })
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    sendItem() {
      if (this.carItems.length > 1) {
        this.showModalSend = true
      } else {
        this.printCotiItems = 'actual'
        this.confirmSend()
      }
    },
    confirmSend() {
      if (this.clientCarShop.name && (this.clientCarShop.email || this.clientCarShop.emails.length > 0)) {
        this.showModalSend = false
        setTimeout(() => {
          this.isSidebarDataClientActive = false
          this.isSidebarSendCotizationActive = true
        }, 50)
      } else {
        this.showModalSend = false
        this.btnName = 'btn-send'
        setTimeout(() => {
          this.isSidebarDataClientActive = true
        }, 50)
      }
    },
    showReturnDataClient() {
      setTimeout(() => {
        this.isSidebarDataClientActive = true
        this.isSidebarSendCotizationActive = false
      }, 50)
    },
    confirmSendCotization() {
      const procesar = true
      if (procesar) {
        this.updateLoadingBtn(true)
        const jsonCar = {
          items: [this.generateJsonCar],
          data_client: this.clientCarShop,
          action: 'send',
        }

        this.axios

        // .post('reservations/generate-cotization-send', jsonCar, {
          .post('reservations/generate-cotization', jsonCar, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success) {
              this.$toast.success(this.$t('msg.sendCotization'))

              // LIMPIAR COTIZADOR
              setTimeout(() => {
                this.clearCotizador('send')
              }, 100)
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.updateLoadingBtn(false)
            this.isSidebarSendCotizationActive = false
          })
      }
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
