<template>
  <div v-if="!isLoading">
    <div>
      <v-container grid-list-xs>
        <v-row>
          <v-col
            cols="12"
            class="px-0"
          >
            <a
              style="text-decoration: none; cursor: pointer;"
              @click="goBack()"
            >
              <p
                class="mb-0"
                style="color:gray"
              >
                <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
                {{ $t('lbl.backSearch') }}
              </p>
            </a>
          </v-col>
        </v-row>
      </v-container>
      <v-container grid-list-xs>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <Car />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <PVPCar />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronLeft,
  mdiBagSuitcase,
  mdiBagPersonal,
  mdiInformation,
} from '@mdi/js'

import Car from '../utils/Car.vue'
import PVPCar from '../utils/PVPCar.vue'

export default {
  components: {
    Car,
    PVPCar,
  },
  data() {
    return {
      itemsOrigen: [],
      isLoading: true,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      vrad: 0,
      value: null,
      value1: null,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronLeft,
        mdiBagSuitcase,
        mdiBagPersonal,
        mdiInformation,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorCars: state => state.app.cotizadorCars,
      cotizadorCarsResult: state => state.app.cotizadorCarsResult,
      cotizadorCarsResultSelect: state => state.app.cotizadorCarsResultSelect,
    }),
  },
  mounted() {
    this.load()
  },
  methods: {
    ...mapMutations(['deleteDestinoCotizadorFligth', 'setSearchingFlightCotizador', 'setPosCotizador']),
    goBack() {
      this.setPosCotizador(1)
      this.setSearchingFlightCotizador(false)
      this.$router.push({ name: 'cotizador' })
    },
    load() {
      if (this.cotizadorCarsResultSelect === null) {
        this.$router.push({ name: 'cotizador' })
      }

      this.isLoading = false
    },
  },
}
</script>

<style>
.topMine {
  top: 70px !important;
}

@keyframes example {
  from {
    top: auto;
  }

  to {
    top: 70px;
  }
}

.topMine {
  animation-name: example;
  transition-duration: 2s;
  animation-duration: 1s;
  scroll-behavior: smooth;
}

#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

#timeLineMovilMine.v-timeline:before {
  bottom: 0;
  content: '';
  height: 35%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px !important;
}

.p12Mine {
  font-size: 12px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div.col-md-8.col-12
  > div:nth-child(3)
  > div
  > div.container.grid-list-xs
  > div
  > div:nth-child(6)
  > div {
  margin-top: 5px;
}

::v-deep .descuento .v-label {
  font-size: 14px !important;
}
</style>
